import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'gatsby'

const FeatureGrid = ({ gridItems, showLinks }) => (
  <div className="columns is-multiline">
    {gridItems.map(item => (
      <div key={item.text} className="column is-6">
        <section className="section" style={{paddingBottom:0}}>
          <div className="has-text-centered">
            <div
              style={{
                width: '240px',
                display: 'inline-block',
              }}
            >
            <FontAwesomeIcon icon={item.icon} size="9x" color="#406eb7"/>
            </div>
          </div>
          <h3>{item.title}</h3>
          <p style={{marginTop:'1.5rem'}}>{item.text}</p>
          {showLinks?(<Link className="btn" to={item.link}>Learn more</Link>):''}
        </section>
      </div>
    ))}
  </div>
)

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  showLinks: PropTypes.bool
}

export default FeatureGrid
